import React from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import Language from "./language"

class Nav extends React.Component {

    state = {
        menuActive: false,
    }

    render() {
        return (
            <div>
                <div className="fixed w-full z-30">
                    <div className="mx-auto h-20 bg-mt-background">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 h-20">
                            <div>
                                <div className="absolute top-nav-padding left-5 hidden sm:block">
                                    <Language />
                                </div>
                            </div>
                            <div className="text-center">
                                <Link to="/">
                                    <img src={'/img/mt-logo-en.svg'} className="mx-auto mt-5 h-10" alt="Logo" />
                                </Link>
                            </div>
                            <div className="">

                                <div className="absolute top-nav-padding right-16 text-right hidden md:block">
                                    <Link
                                        to="/workshops"
                                        className="ml-3 text-mt-black-500 hover:text-mt-black-800"
                                        activeClassName="text-mt-black-800"
                                        partiallyActive={true}>
                                        <FormattedMessage id="site.menu.workshops" />
                                    </Link>
                                    <Link
                                        to="/events"
                                        className="ml-3 text-mt-black-500 hover:text-mt-black-800 hidden lg:inline-block"
                                        activeClassName="text-mt-black-800"
                                        partiallyActive={true}>
                                        <FormattedMessage id="site.menu.events" />
                                    </Link>
                                    <a 
                                        href="https://shop.metsiktoit.ee"
                                        className="ml-3 text-mt-black-500 hover:text-mt-black-800 hidden lg:inline-block"
                                        activeClassName="text-mt-black-800"
                                        partiallyActive={true}>
                                        <FormattedMessage id="site.menu.shop" />
                                    </a>

                                    <Link
                                        to="/about"
                                        className="ml-3 text-mt-black-500 hover:text-mt-black-800"
                                        activeClassName="text-mt-black-800"
                                        partiallyActive={true}>
                                        <FormattedMessage id="site.menu.about" />
                                    </Link>

                                    <Link
                                        to="/contact"
                                        className="ml-3 text-mt-black-500 hover:text-mt-black-800"
                                        activeClassName="text-mt-black-800"
                                        partiallyActive={true}>
                                        <FormattedMessage id="site.menu.contact" />
                                    </Link>
                                </div>
                                <div className="absolute top-5 right-5 block">
                                    <button
                                        className={this.state.menuActive ? "square switch-on" : "square switch-off"}
                                        onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                                        <div className={this.state.menuActive ? "hidden" : "inline-block"}>
                                            <div className="cursor-pointer text-3xl text-mt-black-500 hover:text-mt-black-800">
                                                <i className="las la-bars"></i>
                                            </div>
                                        </div>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.menuActive ? "block" : "hidden"}>
                    <div className="transition-all duration-700 ease-in-out fixed top-0 left-0 w-full h-full bg-mt-background opacity-95 z-40">
                        <div className="text-center">
                            <Link to="/">
                                <img src={'/img/mt-logo-en.svg'} className="mx-auto mt-5 h-10" alt="Logo" />
                            </Link>
                        </div>

                        <button
                            className="absolute top-5 right-5 cursor-pointer text-3xl text-mt-black-800"
                            onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                            <i class="las la-times"></i>
                        </button>
                        <div className="container mx-auto p-8 md:p-16 lg:p-24">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                <div>
                                    <p className="typo-subtitle mb-2"><FormattedMessage id="site.menu.title.services" /></p>
                                    <ul>
                                        <li>
                                            <Link
                                                to="/workshops"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.workshops" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/events"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.events" />
                                            </Link>
                                        </li>
                                        <li>
                                            <a 
                                            href="https://shop.metsiktoit.ee"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.shop" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <p className="typo-subtitle mb-2"><FormattedMessage id="site.menu.title.projects" /></p>
                                    <ul>
                                        <li>
                                            <Link
                                                to="/plants"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.plants" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/ivanchai"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.ivanchai" />
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                                <div>
                                    <p className="typo-subtitle mb-2"><FormattedMessage id="site.menu.title.about" /></p>
                                    <ul>
                                        <li>
                                            <Link
                                                to="/thoughts"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.thoughts" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/about"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.about" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/contact"
                                                className="typo-text-xl text-mt-black-500 hover:text-mt-black-800"
                                                activeClassName="text-mt-black-800"
                                                partiallyActive={true}>
                                                <FormattedMessage id="site.menu.contact" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mt-8 md:mt-16">
                                <p className="typo-subtitle mb-2"><FormattedMessage id="site.menu.title.language" /></p>
                                <Language />
                            </div>





                        </div>
                    </div>

                </div>
                <div className="h-20"></div>
            </div>

        )
    }
}

export default Nav


