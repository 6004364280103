
import React from 'react'
import { FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"
import LanguageFooter from "./languageFooter"

const Footer = () => {
    return (
        <div className="bg-mt text-white py-20">
            <div className="container mx-auto px-10">
                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-10">
                    <div className="sm:col-span-3 md:col-span-1">
                        <Link to="/">
                            <img src={'/img/mt-logo-white.svg'} className="h-5" alt="Logo" />
                        </Link>
                        <div className="mt-3">
                            <LanguageFooter />
                        </div>
                    </div>
                    <div>
                        <h4 className="typo-subtitle mb-2"><FormattedMessage id="footer.products" /></h4>
                        <ul>
                            <li><Link to="/workshops"><FormattedMessage id="footer.workshops" /></Link></li>
                            <li><Link to="/events"><FormattedMessage id="footer.events" /></Link></li>
                            <li><FormattedHTMLMessage id="footer.shop" /></li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="typo-subtitle mb-2"><FormattedMessage id="footer.company" /></h4>
                        <ul>
                            <li><Link to="/"><FormattedMessage id="footer.intro" /></Link></li>
                            <li><Link to="/about"><FormattedMessage id="footer.about" /></Link></li>
                            <li><Link to="/contact"><FormattedMessage id="footer.contact" /></Link></li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="typo-subtitle mb-2"><FormattedMessage id="footer.follow" /></h4>
                        <ul>
                            <li><Link to=""><FormattedMessage id="footer.mailinglist" /></Link></li>
                            <li><Link to=""><FormattedMessage id="footer.instagram" /></Link></li>
                            <li><Link to=""><FormattedMessage id="footer.facebook" /></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer


