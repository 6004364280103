import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  et: "EE",
  en: "EN",
}

const LanguageFooter = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <button
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                "margin-right": 10,
                cursor: `pointer`,
                color: `white`,
              }}
              className={currentLocale === language ? `opacity-100` : `opacity-50`}
            >
              {languageName[language]}
            </button>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default LanguageFooter
